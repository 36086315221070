<template>
	<div class="app">
		<div class="app-swiper" :style="style">
			<swiper :options="swiperOption">
				<swiper-slide class="swiper-slide">
					<div class="swiper-item">
						<div class="left">
							<img src="../assets/img/app0101.png" class="app0101" />
							<div class="info">
								<div class="qrcode">
									<div id="qrcode-image" ref="qrcode-image" class="qrcode-image" />
									<div>{{ $t('goToScan') }}</div>
								</div>
								<div class="downloader">
									<a :href='iosUrl'><img src="../assets/img/app0104.png" /></a>
									<a :href='androidUrl' @click="emitEvent"><img src="../assets/img/app0105.png" /></a>
								</div>
							</div>
						</div>
						<div class="right"><img src="../assets/img/app0110.png" class="app0101" /></div>
					</div>
				</swiper-slide>
				<swiper-slide class="swiper-slide">
					<div class="swiper-item">
						<div class="left left-center">
							<img src="../assets/img/app0201.png" class="app0201" />
							<div class="text">
								{{ $t('adContent1') }}
								<br />
								{{ $t('adContent2') }}
							</div>
						</div>
						<div class="right"><img src="../assets/img/app0120.png" class="app0101" /></div>
					</div>
				</swiper-slide>
				<swiper-slide class="swiper-slide">
					<div class="swiper-item">
						<div class="left left-center">
							<img src="../assets/img/app0301.png" class="app0301" />
							<div class="text">
								{{ $t('adContent3') }}
								<br />
								{{ $t('adContent4') }}
							</div>
						</div>
						<div class="right"><img src="../assets/img/app0130.png" class="app0101" /></div>
					</div>
				</swiper-slide>
				<swiper-slide class="swiper-slide">
					<div class="swiper-item">
						<div class="left left-center">
							<img src="../assets/img/app0401.png" class="app0401" />
							<div class="text">
								{{ $t('adContent5') }}
								<br />
								{{ $t('adContent6') }}
							</div>
						</div>
						<div class="right"><img src="../assets/img/app0140.png" class="app0101" /></div>
					</div>
				</swiper-slide>
				<swiper-slide class="swiper-slide">
					<div class="swiper-item">
						<div class="left">
							<img src="../assets/img/app0101.png" class="app0101" />
							<div class="info">
								<div class="qrcode">
									<!--									<img src="../assets/img/download-qrcode-dev.png" v-if="isDev" />-->
									<!--									<img src="../assets/img/download-qrcode.png" v-else />-->
									<div id="qrcode-image2" ref="qrcode-image" class="qrcode-image" />
									<div>{{ $t('goToScan') }}</div>
								</div>

								<div class="downloader">
									<a :href='iosUrl'><img src="../assets/img/app0104.png" /></a>
									<a :href='androidUrl' @click="emitEvent"><img src="../assets/img/app0105.png" /></a>
								</div>
							</div>
						</div>
						<div class="right"><img src="../assets/img/app0150.png" class="app0101" /></div>
					</div>
				</swiper-slide>
				<div class="swiper-button-prev" slot="button-prev"></div>
				<div class="swiper-button-next" slot="button-next"></div>
			</swiper>

			<div class="swiper-pagination" :style="{ width: style.width }"></div>
		</div>
	</div>
</template>

<script>
import { debounce } from '@/utils/index.js';
import { DEV_MODE } from '@/utils/constants.js'
import { mapState } from 'vuex';
import QRCode from "qrcodejs2";
export default {
	data() {
		return {
			style: {
				width: 'auto',
				height: 'auto'
			},
			swiperOption: {
				pagination: '.swiper-pagination',
				paginationClickable: true,
				prevButton: '.swiper-button-prev',
				nextButton: '.swiper-button-next'
			},
			isDev: location.hostname == 'dev-web.01tiyu.com'
		};
	},
	computed: mapState(['iosUrl', 'androidUrl']),
	mounted() {
		window.addEventListener('resize', debounce(this.calcRect, 300));
		this.calcRect();
		this.$store.dispatch('getDownloadUrls');
		setTimeout(this.makeQrcode, 0)
	},
	methods: {
		calcRect(e) {
			const style = window.getComputedStyle(this.$root.$el);
			let width = parseFloat(style.width);
			let height = Math.max(parseFloat(style.height), 500) - 100;
			if (width / 2 > height - 100) {
				width = (height - 100) * 2;
			} else {
				height = width / 2 + 100;
			}
			this.style.width = width + 'px';
			this.style.height = height + 'px';
		},
		makeQrcode() {
			// const hostname = (location.hostname).split('.')
			// const vhost = hostname.slice(hostname.length - 2)
			// vhost.unshift(DEV_MODE ? 'dev-m' : 'm')
			const text = location.origin + '/app'
			new QRCode("qrcode-image", {
				width: 150, //宽度
				height: 150, // 高度
				text: text
			});
			new QRCode("qrcode-image2", {
				width: 150, //宽度
				height: 150, // 高度
				text: text
			});
		},
		emitEvent() {
			if (location.hostname == 'gg.0ldy.com' && typeof gtag == 'function') {
				gtag('event', 'conversion', {
					'send_to': 'AW-16507486875/Uta3CPzN654ZEJuFsb89'
				});
				gtag('event', 'conversion', {
					'send_to': 'AW-16490997643/9ry7CPDBy50ZEIvPwrc9'
				});
			} else if (location.hostname == 'ggtg.0ldy.com' && typeof gtag == 'function') {
				gtag('event', 'conversion', {
					'send_to': 'AW-16752056980/jTDoCPDUjuEZEJS1gLQ-'
				});
			}
		}
	}
};
</script>

<style lang="less">
body.page-app {
	.app {
		height: 100%;
		background: url(../assets/img/downloadbg.png) no-repeat center center;
		background-size: cover;
	}

	.footer {
		display: none;
	}

	.app-swiper {
		padding: 50px 0;
		margin-left: auto;
		margin-right: auto;
		box-sizing: border-box;

		.swiper-container {
			width: 100%;
			height: 100%;
			background: url(../assets/img/swiperbg.png) no-repeat center center;
			background-size: contain;
		}

		.swiper-item {
			width: 100%;
			height: 100%;
			position: relative;
			padding-left: 16%;
			padding-right: 12.5%;
			padding-top: 0;
			display: flex;
			flex-direction: row;
			align-items: center;
			box-sizing: border-box;

			// height: auto;
			.left {
				flex: 1;
				display: flex;
				flex-direction: column;

				&.left-center {
					position: relative;
					align-items: center;
				}
			}

			.right {
				width: 30%;
				margin-left: 16%;
			}

			.text {
				width: 100%;
				margin-top: 20%;
				font-size: 1.2vw;
				color: #ffffff;
				text-align: center;
			}

			.app0201,
			.app0301,
			.app0401 {
				width: 61.25%;
			}
		}

		.swiper-slide {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}

		.swiper-button-prev {
			left: 100px;
			background-size: 18px 34px;
		}

		.swiper-button-next {
			right: 100px;
			background-size: 18px 34px;
		}

		.swiper-button-disabled {
			display: none;
		}

		.info {
			position: relative;
			margin-top: 20%;
			display: flex;
			flex-direction: row;
			font-size: 1.2vw;

			.qrcode {
				flex: 1;
				color: #ffffff;
				text-align: center;

				.qrcode-image {
					width: 100%;
					background-color: #ffffff;
					padding: 4%;
					margin-bottom: 8%;
				}

				img {
					width: 100% !important;
					height: auto !important;
					display: block;
				}
			}

			.downloader {
				margin-left: 16%;
				width: 50%;

				a,
				img {
					display: block;
				}

				a {
					margin-bottom: 18%;
				}
			}
		}

		img {
			max-width: 100%;
		}
	}

	.swiper-pagination {
		height: 50px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		.swiper-pagination-bullet {
			opacity: 1;
			width: 16px;
			height: 16px;
			background: #ffffff;
			border-radius: 50%;

			+.swiper-pagination-bullet {
				margin-left: 28px;
			}

			&.swiper-pagination-bullet-active {
				background: var(--primary-color);
			}
		}
	}
}
</style>
